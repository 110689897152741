import axios, { CancelToken, isCancel } from 'axios';

import ApiError, { DEFAULT_ERROR, FIVE_HUNDRED_ERROR } from './ApiError';
import Deserializer from './Deserializer';

const apiPath = window.apiPath || '/api/';
const api = axios.create({ baseURL: apiPath, withCredentials: true });

api.interceptors.request.use(( config ) => {
  const meta = document.querySelector( 'meta[name="csrf-token"]' );

  // Just in case (for example when testing)
  if ( meta ) {
    config.headers['X-CSRF-TOKEN'] = meta.getAttribute( 'content' );
  }

  return config;
});

export {
  api as default, ApiError, CancelToken, Deserializer, isCancel, DEFAULT_ERROR, FIVE_HUNDRED_ERROR
};
